@import "../../index.scss";

.login-info {
  font-family: Nunito;
  display: flex;
  flex-direction: row;
  background-color: $main-background;

  @media (max-width: $tablet_screen) {
    flex-direction: column-reverse;
  }

  .introduction {
    display: flex;
    @media (max-width: $mobile_screen) {
      min-height: 98vh;
    }
    @media (min-width: $tablet_screen) {
      @media (min-width: 992px) {
        min-height: 98vh;
      }
      min-height: 70vh;
      flex: 2;
    }

    .image-container {
      height: 60vh;
      @media (max-width: 992px) {
        margin-top: 0px !important;
        height: 40vh;
        margin-left: 15px;
      }
      @media (max-width: $mobile_screen) {
        height: fit-content;
        min-height: 50vh;
        margin: 20px 5px !important;
      }
    }

    .slide-carousel {
      width: 100%;
      .description-container {
        display: flex;
        flex-direction: column;
        margin-left: 10vw;
        @media (max-width: 992px) {
          margin-left: 2rem;
          margin-bottom: 50px;
        }
        @media (max-width: $tablet_screen) {
          margin-left: 15vw;
        }
        @media (max-width: $mobile_screen) {
          margin-left: 1rem;
        }
        .title {
          font-size: 24px;
          text-transform: uppercase;
          color: white;
          margin: 2px;
          font-weight: bold;
          margin-top: 10px;
          margin-bottom: 20px;
          @media (max-width: 992px) {
            margin-bottom: 0px;
            margin-top: 0px;
          }
          @media (max-width: $tablet_screen) {
            font-size: 18px;
            margin-bottom: 1.5rem;
          }
          @media (max-width: $mobile_screen) {
          }
        }

        .description-detail {
          font-size: 20px;
          color: white;
          margin: 2px;

          @media (max-width: $tablet_screen) {
            font-size: 16px;
          }
        }

        .bt-registration {
          width: 150px;
          padding: 5px;
          height: 50px;
          font-size: 18px;
          margin: 10px 0px 10px 0px;
          background-color: rgb(5, 160, 152);
          color: white;
          border-width: 0px;
          @media (max-width: $mobile_screen) {
            margin: 1rem 0;
          }
        }

        .free-trail {
          font-size: 15px;
          color: white;
        }
      }

      .image-container {
        margin-top: 50px;
        width: 100%;
        display: flex;
        justify-content: center;

        .slide-image {
          width: 45vw;
          // height: 60vh;
          object-fit: scale-down;
          justify-self: center;

          @media only screen and (max-width: $tablet_screen) {
            width: 95vw;
            // height: 70vw;
            margin-top: 0px;
          }
        }
      }
    }
  }

  .login-form-container {
    display: flex;
    z-index: 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 150px auto;
    padding: 0 20px 0;

    @media (max-width: $tablet_screen) {
      flex: 1;
      // margin-top: 80px;
    }

    @media (max-width: $mobile_screen) {
      // display: none;
    }

    .app-store {
      margin: 10px 0;
      display: flex;
      justify-content: space-between;

      .icon_app_store {
        height: 45px;
        margin: 2%;
        padding: 0 30px;

        &hover {
          cursor: pointer;
        }

        @media (max-width: $tablet_screen) {
          height: 42px;
        }
      }
    }

    .login-form {
      width: 380px;
      height: 400px;
      border-radius: 10px;
      background-color: $white;
      padding-right: 20px;
      padding-left: 20px;

      @media (max-width: $tablet_screen) {
        width: 320px;
      }

      .login-logo {
        padding: 10px;
        display: flex;
        justify-content: center;

        .logo {
          height: 50px;

          @media (max-width: $tablet_screen) {
            height: 40px;
          }
        }
      }

      .login-form-forgot {
        padding-top: 5px;
        float: right;
        color: $main;
        text-decoration: underline;
      }

      .view-error {
        height: 20px;
        margin-bottom: 10px;
      }

      .login-error {
        color: $red;
        font-size: $normal;
        text-align: center;
      }

      .login-form-button {
        width: 100%;
        background-color: $main;
        border-width: 0px;
        // margin-top: 10px;
      }

      .textRegister {
        text-align: center;
        margin-top: 20px;

        .txtLinkRegister {
          color: $main;
        }

        .txtAskRegister {
          float: right;
          text-align: center;
          padding-right: 35px;
          padding-left: 5px;
        }
      }

      .contaiImage {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .iconApp {
          width: 140px;
          cursor: pointer;
          margin-bottom: 20px;
        }
      }
    }
  }
}
