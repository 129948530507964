@import "../../index.scss";
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";

.nav-auth {
  overflow: hidden;
  height: 60px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: fixed;
  width: 100vw;
  z-index: 999;
  padding: 0px 80px 0px 80px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  * {font-family: Nunito;}
  @media (max-width: 992px) {
    padding: 0px 40px 0px 40px;
  };
  @media (max-width: 768px) {
    padding: 0px 20px 0px 20px;
  }
  .logo {
    height: 50px;
    margin: 0px;
    &:hover {
      cursor: pointer;
    }
  }
  .info {
    display: flex;
    justify-content: center;
    flex-direction: row;
    @media (max-width:  992px) {
      display: none;
    }
    .text-item {
      align-self: center;
      font-size: 18px;
      display: flex-end;
      margin: 0px 0px 0px 20px;
      &:hover {
        color: #6fdae2;
        cursor: pointer;
      }
    }
  }
  .menu {
    justify-content: center;
    height: 30px;
    @media (min-width: 992px) {
      display: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .menu-nav {
    background-color: white;
    position: fixed;
    width: 300px;
    height: 100vh;
    top: 0;
    left: -100%;
    transition: left 0.3s;
    &.open {
      left: 0%;
    }
    .menu-item{
      display: flex;
      height: 60px;
      padding: 20px;
      justify-content: space-between;
      align-items: center;
      border-bottom-width: 2px;
      border-bottom-color: gray;
      &:hover {
        background-color: #6fdae2;
      }
      .icon-close {
        height: 20px;
        border-color:transparent;
      }
      .text {
        font-size: 18px;
      }
      .icon_app_store {
        height: 35px;
      }
    }
    
  }
}